import '../sass/project.scss';

window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');

/* Project specific Javascript goes here. */
const setupToasts = () => {
  const toastElList = document.querySelectorAll('.toast');

  if (toastElList.length === 0) {
    return;
  }

  const toastList = [...toastElList].map(
    (toastEl) => new bootstrap.Toast(toastEl, { delay: 10000 }),
  );

  toastList.forEach((toast) => {
    toast.show();
  });
};

const setupTooltips = () => {
  const tooltipTriggerList = document.querySelectorAll(
    '[data-bs-toggle="tooltip"]',
  );

  if (tooltipTriggerList.length === 0) {
    return;
  }

  tooltipTriggerList.forEach((tooltipTriggerEl) => {
    new bootstrap.Tooltip(tooltipTriggerEl);
  });
};

window.addEventListener('DOMContentLoaded', () => {
  /* Run whatever you want */
  setupToasts();
  setupTooltips();
});
